<script>
import { ValidationObserver } from 'vee-validate'
import { Pipeline } from '/~/core/pipeline'
import Storage from '/~/utils/localStorage'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useForm } from '/~/composables/base/use-form'
import { useLocalization } from '/~/composables/localization'
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'

export default {
  name: 'lead-submission-form',
  components: {
    BaseInput,
    BaseButton,
    BaseTextarea,
    BaseCheckbox,
    LayoutRounded,
    BaseSelect,
    ValidationObserver,
  },
  setup() {
    const { states } = useLocalization()
    const { validationObserverRef } = useForm()

    return {
      states,
      validationObserverRef,
    }
  },

  data() {
    return {
      submitting: false,
      form: {
        email: '',
        channel: '',
        organization: '',
        state: '',
        productsInterested: [],
        contactFirstName: '',
        contactLastName: '',
        contactPhoneNumber: '',
        contactEmail: '',
        reason: '',
      },
      backendErrors: {},
      productsInterestedOptions: [
        'Domestic Parcel Services',
        'Domestic Freight Services',
        'International Parcel Services',
        'Mail Services',
        'Warehousing Solutions',
        'Payment Services',
        'Information and Mailroom Management',
        'Workforce Verification',
      ],
      channelList: [
        'Corporate Post Office / Business Hub',
        'Business Contact Centre',
        'Consumer Contact Centre',
        'Driver Network',
        'Other',
      ],
    }
  },
  computed: {
    channelOptions() {
      return this.channelList.map((channel) => ({
        text: channel,
        value: channel,
      }))
    },
    stateOptions() {
      return this.states.map((state) => ({
        text: state.label,
        value: state.label,
      }))
    },
  },
  methods: {
    clearForm() {
      this.form = {
        email: '',
        channel: '',
        organization: '',
        state: '',
        productsInterested: [],
        contactFirstName: '',
        contactLastName: '',
        contactPhoneNumber: '',
        contactEmail: '',
        reason: '',
      }
      this.backendErrors = {}
    },
    async onSubmit() {
      this.submitting = true

      try {
        const {
          trackingId: [trackingId],
        } = await Pipeline.submit('AustraliaPostLead', this.form)

        this.$notify({
          text: 'Lead successfully added',
          type: 'success',
          duration: 1000,
        })

        // TODO: remove when we add endpoint
        Storage.set(
          `lead-submission-${trackingId}`,
          JSON.stringify({
            ...this.form,
            createdAt: new Date(),
          })
        )

        this.clearForm()
        this.$router.push({
          name: 'lead-submission-confirmation',
          params: {
            id: trackingId,
          },
        })
      } catch (error) {
        this.processBackendErrors(error.data?.errors)
      } finally {
        this.submitting = false
      }
    },
    processBackendErrors(error) {
      if (!error) {
        return
      }
      for (const key in error) {
        if (Object.prototype.hasOwnProperty.call(error, key)) {
          const firstError = error[key][0]

          error[key] = firstError || ''
        }
      }
      this.backendErrors = error
    },
  },
}
</script>

<template>
  <layout-rounded>
    <validation-observer
      v-slot="{ invalid, errors, handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="mx-auto w-full max-w-xl p-2.5 text-sm font-semibold text-eonx-neutral-800 md:py-[35px]"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <h2 class="mb-5">Submit a Lead</h2>
        <h3 class="mb-2.5">Your details</h3>
        <label>
          <span
            :class="[
              'text-base',
              ((errors['email'] && errors['email'][0]) ||
                backendErrors.email) &&
                'text-red-700',
            ]"
          >
            Your email address - we'll email you regularly to let you know the
            status of your lead *
          </span>
          <base-input
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email address',
              vid: 'email',
            }"
            required
            :error="backendErrors.email"
            name="email"
            :disabled="submitting"
            class="-mt-5"
          />
        </label>
        <base-select
          v-model="form.channel"
          :validation="{
            rules: 'required',
            name: 'Business Channel',
          }"
          :error="backendErrors.channel"
          label="Business Channel"
          name="channel"
          :options="channelOptions"
          required
          :disabled="submitting"
        />

        <h3 class="mb-2.5 mt-6">Customer details</h3>

        <base-input
          v-model="form.organization"
          :validation="{
            rules: 'required',
            name: 'Organization',
          }"
          :error="backendErrors.organization"
          label="Organization"
          name="organization"
          required
          :disabled="submitting"
        />

        <div class="grid gap-x-[35px] xs:grid-cols-2">
          <base-input
            v-model="form.contactFirstName"
            :validation="{
              rules: 'required',
              name: 'First Name',
            }"
            :error="backendErrors.contactFirstName"
            label="First Name"
            name="contactFirstName"
            required
            :disabled="submitting"
          />
          <base-input
            v-model="form.contactLastName"
            :validation="{
              rules: 'required',
              name: 'Last Name',
            }"
            :error="backendErrors.contactLastName"
            label="Last Name"
            name="contactLastName"
            required
            :disabled="submitting"
          />
          <base-input
            v-model="form.contactEmail"
            :validation="{
              rules: 'required|email',
              name: 'Contact email address',
            }"
            :error="backendErrors.contactEmail"
            label="Contact email address"
            name="contactEmail"
            required
            :disabled="submitting"
          />
          <base-input
            v-model="form.contactPhoneNumber"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
            }"
            :error="backendErrors.contactPhoneNumber"
            type="tel"
            mask="mobile"
            label="Mobile"
            name="contactPhoneNumber"
            required
            :disabled="submitting"
          />
        </div>

        <base-select
          v-model="form.state"
          :validation="{
            rules: 'required',
            name: 'State',
          }"
          :error="backendErrors.state"
          label="State"
          name="state"
          :options="stateOptions"
          required
          :disabled="submitting"
        />

        <div
          :class="[
            'text-base',
            backendErrors.productsInterested && 'text-red-700',
          ]"
        >
          Products Interested in *:
          <div
            v-if="backendErrors.productsInterested"
            class="text-xs font-bold"
          >
            {{ backendErrors.productsInterested }}
          </div>
        </div>
        <div
          class="mb-6 mt-[5px] grid gap-x-[35px] gap-y-2.5 font-normal xs:grid-cols-2"
        >
          <base-checkbox
            v-for="(option, index) in productsInterestedOptions"
            :key="index"
            v-model="form.productsInterested"
            :value="option"
            :disabled="submitting"
          >
            {{ option }}
          </base-checkbox>
        </div>

        <label>
          <span class="text-base">
            Why is this lead being submitted? Please include the customer's
            preferred contact method and time (Optional)
          </span>
          <base-textarea
            v-model="form.reason"
            :disabled="submitting"
            :error="backendErrors.reason"
            :maxlength="400"
            :rows="4"
            class="-mt-5"
            name="message"
          />
        </label>

        <base-button
          full-width
          class="mt-5"
          type="submit"
          :loading="submitting"
          :disabled="invalid"
        >
          Submit
        </base-button>

        <div class="mt-[15px] text-base">
          <span>Please note: if you collect an individual's name please:</span>
          <ul class="list-disc pl-5">
            <li>
              Advise the individual that their details will be passed to
              Australia Post so that they can be contacted by an Australia Post
              representative; and
            </li>
            <li>
              Refer them to the Seeker Customer Privacy Statement
              <a
                href="https://seekerrewards.com.au/privacy_policy"
                class="break-words text-primary"
              >
                https://seekerrewards.com.au/privacy_policy
              </a>
            </li>
          </ul>
        </div>
      </form>
    </validation-observer>
  </layout-rounded>
</template>
